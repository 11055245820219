import React from "react"
import styled from "styled-components"

const Logo = () => {
  return (
    // <StarterLogo xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 171 86">
    <StarterLogo xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 240 86">
      <g id="Group_40" data-name="Group 40" transform="translate(-24 -1478)">
        <text id="eno.ng" transform="translate(24 1547)" fill="#a7c957" fontSize="61" fontFamily="ChalkboardSE-Regular, Chalkboard SE"><tspan x="0" y="0">eno.ng</tspan></text>
        <path id="Union_6" data-name="Union 6" d="M13.392,10.25c-2.262.042-9.077.109-11.8-.547C-1.686,8.911.6-.746,3.885.046,6.736.734,12.379,7.929,13.8,9.794l.051-.057-.644-.716.644.716.1-.107-.1.107.048.053C15.33,7.92,20.967.734,23.817.046,27.1-.746,29.388,8.911,26.105,9.7c-2.716.655-9.522.589-11.79.547l5.8,6.45-5.8-6.45-.465-.01-.459.009L7.043,17.31Zm.459-.393L13.9,9.79l-.048-.053-.051.057Z" transform="translate(57.243 1497.67)" fill="none" stroke="#a7c957" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </g>
    </StarterLogo>
  )
}

export default Logo

const StarterLogo = styled.svg`
  height: 66px;
  width: 171px;
`
